import React from 'react'
import Cart from '@components/Cart'

const CartPage = () => (
  <div>
    <h1>Cart</h1>
    <Cart />
  </div>
)

export default CartPage
